import React, { useState } from "react";
import { useIntl, navigate } from "gatsby-plugin-react-intl";
import Layout from "../layouts/Layout";
import Seo from "../components/Seo/Seo";
import API from "../api";
import { ToastContainer, toast } from "react-toastify";
import queryString from "query-string";
import { size as _size } from "lodash";

const ResetPassword = () => {
  const intl = useIntl();
  const [errors, setErrors] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

  const resetPasswordCb = ev => {
    ev.preventDefault();
    setErrors("");
    const urlParams = queryString.parse(window.location.search);
    API.post("users/update-password", {
      new_password: newPassword,
      new_password_confirmation: newPasswordConfirmation,
      token: urlParams?.token,
      email: urlParams?.email,
    })
      .then(() => {
        setNewPassword("");
        setNewPasswordConfirmation("");
        toast.success(
          intl.formatMessage({
            id: "your_password_has_been_changed",
            defaultMessage: "Your password has been changed",
          }),
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
          }
        );

        setTimeout(function () {
          navigate(`/`);
        }, 2000);
      })
      .catch(err => setErrors(err.data));
  };

  return (
    <Layout
      section={"payment"}
      bannerTitle={intl.formatMessage({
        id: "reset_password",
        defaultMessage: "Reset Password",
      })}
    >
      <section className="section homepage-content">
        <div className="container">
          <>
            <div className="columns">
              <div className="column">
                <div className="content">
                  <div className="columns">
                    <div className="column">
                      <div className="profile-content-wrapper">
                        <h3>
                          {intl.formatMessage({
                            id: "set_your_new_password",
                            defaultMessage: "Set your new password",
                          })}
                        </h3>
                        {_size(errors) > 0 && (
                          <div className="columns">
                            <div className="column is-half">
                              <p className="help is-danger">{errors?.error}</p>
                            </div>
                          </div>
                        )}

                        <form onSubmit={resetPasswordCb}>
                          <div className="columns">
                            <div className="column is-half">
                              <div className="field">
                                <p className="control">
                                  <input
                                    type="password"
                                    name="new_password"
                                    className="input password"
                                    placeholder={intl.formatMessage({
                                      id: "new_password",
                                      defaultMessage: "New Password",
                                    })}
                                    minLength="6"
                                    value={newPassword}
                                    onChange={e =>
                                      setNewPassword(e.target.value)
                                    }
                                    required
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="column is-half">
                              <div className="field">
                                <p className="control">
                                  <input
                                    type="password"
                                    name="new_password_confirmation"
                                    className="input password"
                                    placeholder={intl.formatMessage({
                                      id: "confirm_password",
                                      defaultMessage: "Confirm Password",
                                    })}
                                    minLength="6"
                                    value={newPasswordConfirmation}
                                    onChange={e =>
                                      setNewPasswordConfirmation(e.target.value)
                                    }
                                    required
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column is-half">
                              <button
                                type="submit"
                                className="button save-changes-btn"
                              >
                                {intl.formatMessage({
                                  id: "save",
                                  defaultMessage: "Save",
                                })}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
        <ToastContainer />
      </section>
    </Layout>
  );
};

export default ResetPassword;
export const Head = ({ location, pageContext }) => (
  <Seo
    title={pageContext.intl.messages.reset_password}
    pathname={location.pathname}
  />
);


